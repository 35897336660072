import React, { useState, useEffect } from 'react'
import Marker from '../marker/marker'
import Footer from "../footer/footer"
import Header from "../header/header"
import Home from '../formRequestHome/formRequestHome'
import { Context } from '../context/provider'
import UploadDocuments from '../uploadDocuments/uploadDocuments'
import UploadSummary from '../uploadSummary/uploadSummary'
import PropTypes from 'prop-types'
import { getStorageItem } from '../../utilities/getStorageItem'

export default function FormBase({
  formName, 
  formFile,
  formPath,
  infoApi,
  fee,
  description
}) {
  const [current, setCurrent] = useState(getStorageItem('mcn-form-filled') === 'true' ? 1 : '');

  useEffect(() => {
    if (typeof window != 'undefined') {
      window.scrollTo(0,0)
    }
  }, [current])

  useEffect(() => {
    if (typeof window != 'undefined' && window.location.search.includes('test=')) {
      const search = window.location.search
      const page = search.split('=')[1]
      setCurrent(parseInt(page))
    }
  }, [])

  const markerItems = [
    'Upload documents',
    'Summary',
    'Payment'
  ]

  useEffect(() => {
    if (current === 2) {
      window.location.href = '/payment'
    }
  }, [current])

  return (
    <Context.Provider value={{ current, setCurrent }}>
      <Header title={parseInt(current) >= 0 ? formName : ''} />
      {parseInt(current) >= 0 && current <= 2 && <Marker items={markerItems}/>}
      {current === '' && <Home form={formFile} label={formName} description={description} />}
      {current === 0 && <UploadDocuments fee={fee} formName={formName} />}
      {current === 1 && <UploadSummary {...{ formName, formPath, infoApi }} />}
      <Footer />
    </Context.Provider>
  )
}

FormBase.propTypes = {
  formName: PropTypes.string, 
  formPath: PropTypes.string, 
  formFile: PropTypes.string,
  infoApi: PropTypes.string,
  fee: PropTypes.number,
  description: PropTypes.string
}
