import React from "react"
import Layout from "../components/layout/layout"
import DuplicateTitleRequest from "../components/duplicateTitleRequest/duplicateTitleRequest";

export default function RequestTitle() {
  return (
    <Layout>
      <DuplicateTitleRequest />
    </Layout>
  );
}
