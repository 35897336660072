import React from 'react'
import FormBase from '../formBase/formBase'
import { useSiteConfig } from '../layout/getSiteConfig'

export default function DuplicateTitleRequest() {
  const { duplicateTitleRequestInfoApi } = useSiteConfig()

  return (
    <FormBase {...{
      formName: 'Duplicate Title Request',
      formFile: '/duplicate-title-request.pdf',
      formPath: 'duplicate-title-request',
      infoApi: duplicateTitleRequestInfoApi,
      fee: 5,
      description: 'Duplicate title fee $5. Only the persons whose names appear on the title may request a lost title. If Title says AND, all parties listed on title must fill out a form.'
    }} />
  )
}
